export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/app/src/pages/index.vue'),
    /* no children */
    meta: {
      "layout": "user",
      "access": "private"
    }
  },
  {
    path: '/:path(.*)',
    name: '/[...path]',
    component: () => import('/app/src/pages/[...path].vue'),
    /* no children */
    meta: {
      "layout": "user",
      "access": "private"
    }
  },
  {
    path: '/about',
    name: '/about',
    component: () => import('/app/src/pages/about.vue'),
    /* no children */
    meta: {
      "layout": "user",
      "access": "all"
    }
  },
  {
    path: '/contact',
    name: '/contact',
    component: () => import('/app/src/pages/contact.vue'),
    /* no children */
    meta: {
      "layout": "visitor",
      "access": "all"
    }
  },
  {
    path: '/email',
    /* internal name: '/email' */
    /* no component */
    children: [
      {
        path: 'verify',
        name: '/email/verify',
        component: () => import('/app/src/pages/email/verify.vue'),
        /* no children */
        meta: {
          "layout": "visitor",
          "access": "all"
        }
      }
    ],
  },
  {
    path: '/licensing',
    name: '/licensing',
    component: () => import('/app/src/pages/licensing.vue'),
    /* no children */
    meta: {
      "layout": "visitor",
      "access": "all"
    }
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('/app/src/pages/login.vue'),
    /* no children */
    meta: {
      "layout": "visitor",
      "access": "public"
    }
  },
  {
    path: '/notifications',
    name: '/notifications',
    component: () => import('/app/src/pages/notifications.vue'),
    /* no children */
    meta: {
      "layout": "user",
      "access": "private"
    }
  },
  {
    path: '/privacy-policy',
    name: '/privacy-policy',
    component: () => import('/app/src/pages/privacy-policy.vue'),
    /* no children */
    meta: {
      "layout": "visitor",
      "access": "all"
    }
  },
  {
    path: '/profile',
    name: '/profile',
    component: () => import('/app/src/pages/profile.vue'),
    /* no children */
    meta: {
      "layout": "user",
      "access": "private"
    }
  },
  {
    path: '/reset',
    /* internal name: '/reset' */
    /* no component */
    children: [
      {
        path: '',
        name: '/reset/',
        component: () => import('/app/src/pages/reset/index.vue'),
        /* no children */
        meta: {
          "layout": "visitor",
          "access": "all"
        }
      },
      {
        path: 'confirm',
        name: '/reset/confirm',
        component: () => import('/app/src/pages/reset/confirm.vue'),
        /* no children */
        meta: {
          "layout": "visitor",
          "access": "all"
        }
      }
    ],
  },
  {
    path: '/sign-up',
    name: '/sign-up',
    component: () => import('/app/src/pages/sign-up.vue'),
    /* no children */
    meta: {
      "layout": "visitor",
      "access": "public"
    }
  },
  {
    path: '/workspace',
    /* internal name: '/workspace' */
    /* no component */
    children: [
      {
        path: ':workspace_id',
        /* internal name: '/workspace/[workspace_id]' */
        /* no component */
        children: [
          {
            path: 'billing',
            name: '/workspace/[workspace_id]/billing',
            component: () => import('/app/src/pages/workspace/[workspace_id]/billing.vue'),
            /* no children */
            meta: {
              "layout": "user",
              "access": "private"
            }
          },
          {
            path: 'browse',
            name: '/workspace/[workspace_id]/browse',
            component: () => import('/app/src/pages/workspace/[workspace_id]/browse.vue'),
            /* no children */
            meta: {
              "layout": "tree",
              "access": "private"
            }
          },
          {
            path: 'connection',
            /* internal name: '/workspace/[workspace_id]/connection' */
            /* no component */
            children: [
              {
                path: ':connection_id',
                /* internal name: '/workspace/[workspace_id]/connection/[connection_id]' */
                /* no component */
                children: [
                  {
                    path: 'resource',
                    /* internal name: '/workspace/[workspace_id]/connection/[connection_id]/resource' */
                    /* no component */
                    children: [
                      {
                        path: ':resource_id',
                        /* internal name: '/workspace/[workspace_id]/connection/[connection_id]/resource/[resource_id]' */
                        /* no component */
                        children: [
                          {
                            path: 'documentation',
                            /* internal name: '/workspace/[workspace_id]/connection/[connection_id]/resource/[resource_id]/documentation' */
                            /* no component */
                            children: [
                              {
                                path: '',
                                name: '/workspace/[workspace_id]/connection/[connection_id]/resource/[resource_id]/documentation/',
                                component: () => import('/app/src/pages/workspace/[workspace_id]/connection/[connection_id]/resource/[resource_id]/documentation/index.vue'),
                                /* no children */
                                meta: {
                                  "layout": "tree",
                                  "access": "private"
                                }
                              }
                            ],
                          },
                          {
                            path: 'new',
                            name: '/workspace/[workspace_id]/connection/[connection_id]/resource/[resource_id]/new',
                            component: () => import('/app/src/pages/workspace/[workspace_id]/connection/[connection_id]/resource/[resource_id]/new.vue'),
                            /* no children */
                            meta: {
                              "layout": "tree",
                              "access": "private"
                            }
                          },
                          {
                            path: 'summaries',
                            name: '/workspace/[workspace_id]/connection/[connection_id]/resource/[resource_id]/summaries',
                            component: () => import('/app/src/pages/workspace/[workspace_id]/connection/[connection_id]/resource/[resource_id]/summaries.vue'),
                            /* no children */
                            meta: {
                              "layout": "tree",
                              "access": "private"
                            }
                          },
                          {
                            path: 'summary',
                            /* internal name: '/workspace/[workspace_id]/connection/[connection_id]/resource/[resource_id]/summary' */
                            /* no component */
                            children: [
                              {
                                path: ':summary_id',
                                name: '/workspace/[workspace_id]/connection/[connection_id]/resource/[resource_id]/summary/[summary_id]',
                                component: () => import('/app/src/pages/workspace/[workspace_id]/connection/[connection_id]/resource/[resource_id]/summary/[summary_id].vue'),
                                /* no children */
                                meta: {
                                  "layout": "tree",
                                  "access": "private"
                                }
                              },
                              {
                                path: 'new',
                                name: '/workspace/[workspace_id]/connection/[connection_id]/resource/[resource_id]/summary/new',
                                component: () => import('/app/src/pages/workspace/[workspace_id]/connection/[connection_id]/resource/[resource_id]/summary/new.vue'),
                                /* no children */
                                meta: {
                                  "layout": "tree",
                                  "access": "private"
                                }
                              }
                            ],
                          }
                        ],
                      },
                      {
                        path: 'local',
                        name: '/workspace/[workspace_id]/connection/[connection_id]/resource/local',
                        component: () => import('/app/src/pages/workspace/[workspace_id]/connection/[connection_id]/resource/local.vue'),
                        /* no children */
                        meta: {
                          "layout": "tree",
                          "access": "private"
                        }
                      }
                    ],
                  }
                ],
              },
              {
                path: 'new',
                /* internal name: '/workspace/[workspace_id]/connection/new' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/workspace/[workspace_id]/connection/new/',
                    component: () => import('/app/src/pages/workspace/[workspace_id]/connection/new/index.vue'),
                    /* no children */
                    meta: {
                      "layout": "tree",
                      "access": "private"
                    }
                  },
                  {
                    path: 'bigquery',
                    name: '/workspace/[workspace_id]/connection/new/bigquery',
                    component: () => import('/app/src/pages/workspace/[workspace_id]/connection/new/bigquery.vue'),
                    /* no children */
                    meta: {
                      "layout": "tree",
                      "access": "private"
                    }
                  },
                  {
                    path: 'mysql',
                    name: '/workspace/[workspace_id]/connection/new/mysql',
                    component: () => import('/app/src/pages/workspace/[workspace_id]/connection/new/mysql.vue'),
                    /* no children */
                    meta: {
                      "layout": "tree",
                      "access": "private"
                    }
                  },
                  {
                    path: 'postgresql',
                    name: '/workspace/[workspace_id]/connection/new/postgresql',
                    component: () => import('/app/src/pages/workspace/[workspace_id]/connection/new/postgresql.vue'),
                    /* no children */
                    meta: {
                      "layout": "tree",
                      "access": "private"
                    }
                  },
                  {
                    path: 'snowflake',
                    name: '/workspace/[workspace_id]/connection/new/snowflake',
                    component: () => import('/app/src/pages/workspace/[workspace_id]/connection/new/snowflake.vue'),
                    /* no children */
                    meta: {
                      "layout": "tree",
                      "access": "private"
                    }
                  }
                ],
              }
            ],
          },
          {
            path: 'settings',
            name: '/workspace/[workspace_id]/settings',
            component: () => import('/app/src/pages/workspace/[workspace_id]/settings.vue'),
            /* no children */
            meta: {
              "layout": "user",
              "access": "private"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/workspace/new',
        component: () => import('/app/src/pages/workspace/new.vue'),
        /* no children */
        meta: {
          "layout": "user",
          "access": "private"
        }
      }
    ],
  },
  {
    path: '/workspaces',
    name: '/workspaces',
    component: () => import('/app/src/pages/workspaces.vue'),
    /* no children */
    meta: {
      "layout": "user",
      "access": "private"
    }
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

