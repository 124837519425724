import { defineStore } from 'pinia'
import { useUserStore } from '@/stores/user'
import { authenticated } from '@/main.js';
export const useWorkspaceStore = defineStore('workspace', {
    state: () => ({
        workspaces: [],
        currentWorkspace: null
    }),
    getters: {
        getWorkspace: (state) => (workspaceId) => {
            const workspace = state.workspaces.find(ws => ws.id === workspaceId);
            if (workspace && workspace.paymentMethods) {
                return workspace
            }
            return null;
        },
        getWorkspaceByID(state) {
            return (workspaceID) => state.workspaces.find(w => w.id === workspaceID)
        }
    },
    actions: {
        getSelectedPaymentMethod(workspaceId) {
            const workspace = this.workspaces.find(ws => ws.id === workspaceId);
            if (workspace && workspace.paymentMethods) {
                return workspace.paymentMethods.find(pm => pm.selected);
            }
            return null;
        },
        async fetchWorkspaceStats(workspaceID) {
            try {
                const response = await authenticated.get(`/workspace/${workspaceID}/stats`)
                if (response?.status === 200) {
                    const targetWorkspace = this.workspaces.find(w => w.id === workspaceID)
                    targetWorkspace.stats = response.data
                    if (this.currentWorkspace.id === targetWorkspace.id)
                        this.currentWorkspace.stats = response.data
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async deleteWorkspace(workspaceID) {
            try {
                const response = await authenticated.delete(`/workspace/${workspaceID}`)
                if (response?.status === 200) {
                    const indexToDelete = this.workspaces.findIndex(element => element.id === workspaceID);
                    if (indexToDelete !== -1) {
                        this.workspaces.splice(indexToDelete, 1);
                        if (this.currentWorkspace.id == workspaceID)
                            this.currentWorkspace = this.workspaces[0]
                    }
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async deleteWorkspace(workspaceID) {
            try {
                const response = await authenticated.delete(`/workspace/${workspaceID}`)
                if (response.status === 200) {
                    const indexToDelete = this.workspaces.findIndex(element => element.id === workspaceID);
                    if (indexToDelete !== -1) {
                        this.workspaces.splice(indexToDelete, 1);
                        if (this.currentWorkspace.id == workspaceID)
                            this.currentWorkspace = this.workspaces[0]
                    }
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async fetchWorkspace(workspaceID) {
            try {
                const response = await authenticated.get(`/workspace/${workspaceID}`)
                if (response?.status === 200) {
                    const indexToDelete = this.workspaces.findIndex(element => element.id === workspaceID);
                    if (indexToDelete !== -1) {
                        this.workspaces.splice(indexToDelete, 1);
                        if (this.currentWorkspace.id == workspaceID)
                            this.currentWorkspace = this.workspaces[0]
                    }
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async fetchAvailableSubscriptions(workspaceID) {
            try {
                const response = await authenticated.get(`/workspace/${workspaceID}/subscriptions`)
                if (response?.status === 200) {
                    return response.data
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async fetchCurrentSubscription(workspaceID) {
            try {
                const response = await authenticated.get(`/workspace/${workspaceID}/subscription`)
                if (response?.status === 200) {
                    const index = this.workspaces.findIndex(x => x.id == workspaceID);
                    if (index !== -1) {
                        this.workspaces[index] = {
                            ...this.workspaces[index],
                            subscription: response.data.tier_name
                        };
                        return response.data;
                    }
                    return response.data
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async editWorkspace(workspaceID, body) {
            try {
                const response = await authenticated.patch(`/workspace/${workspaceID}`, body)
                if (response?.status === 200) {
                    const targetWorkspaceIndex = this.workspaces.findIndex(w => w.id === response.data.id);
                    if (targetWorkspaceIndex !== -1) {
                        this.workspaces[targetWorkspaceIndex] = response.data;
                    }
                    return response.data
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async changeSubscriptionTier(workspaceID, newTier) {
            try {
                const response = await authenticated.patch(`/workspace/${workspaceID}/subscription`, { 'tier_name': newTier })
                if (response?.status === 200) {
                    const index = this.workspaces.findIndex(x => x.id == workspaceID);
                    if (index !== -1) {
                        this.workspaces[index] = {
                            ...this.workspaces[index],
                            subscription: response.data.tier_name
                        };
                        return response.data;
                    }
                    return response.data
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async deleteWorkspace(workspaceID) {
            try {
                const response = await authentificated.delete(`/workspace/${workspaceID}`)
                if (response.status === 200) {
                    const indexToDelete = this.workspaces.findIndex(element => element.id === workspaceID);
                    if (indexToDelete !== -1) {
                        this.workspaces.splice(indexToDelete, 1);
                        if (this.currentWorkspace.id == workspaceID)
                            this.currentWorkspace = this.workspaces[0]
                    }
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async fetchWorkspaces() {
            try {
                const response = await authenticated.get(`/me/workspaces`)
                if (response?.status === 200) {
                    this.workspaces = response.data['workspaces']
                    const selectedWorkspace = response.data['workspaces'].find(w => w.id === response.data['selected_workspace_id'])
                    if (!selectedWorkspace)
                        this.currentWorkspace = response.data['workspaces'][0]
                    else
                        this.currentWorkspace = selectedWorkspace
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async uploadPicture(workspaceID, file) {
            try {
                const userStore = useUserStore()
                const formData = new FormData();
                const headers = {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${userStore.token}`
                }
                formData.append("upload_file", file);
                const response = await authenticated.put(`/workspace/${workspaceID}/image`, formData, { headers })
                if (response?.status === 200) {
                    const targetWorkspaceIndex = this.workspaces.findIndex(w => w.id === response.data.id);
                    if (targetWorkspaceIndex !== -1) {
                        this.workspaces[targetWorkspaceIndex] = response.data;
                    }
                }
            } catch (error) {
                throw (error)
            }
        },
        async inviteUsers(workspaceID, emails) {
            try {
                await authenticated.post(`/workspace/${workspaceID}/invitations`, emails)
            } catch (error) {
                throw (error)
            }
        },
        async createWorkspace(body) {
            try {
                const response = await authenticated.post(`/workspace`, body)
                if (response?.status === 200) {
                    this.workspaces.push(response.data)
                    return response.data
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async addPaymentMethod(workspaceID, paymentMethodID, billing_details) {
            try {
                const response = await authenticated.post(`/workspace/${workspaceID}/payment`, {
                    "stripe_id": paymentMethodID,
                    ...billing_details
                })
                if (response?.status === 200) {
                    const workspaceIndex = this.workspaces.findIndex(w => w.id === workspaceID);
                    if (workspaceIndex !== -1) {
                        this.workspaces[workspaceIndex].paymentMethods.push(response.data);
                        this.workspaces = [...this.workspaces];
                        return response.data;
                    }
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async fetchPaymentMethods(workspaceID) {
            try {
                const response = await authenticated.get(`/workspace/${workspaceID}/payments`)
                if (response?.status === 200) {
                    const index = this.workspaces.findIndex(x => x.id == workspaceID);
                    if (index !== -1) {
                        this.workspaces[index] = {
                            ...this.workspaces[index],
                            paymentMethods: response.data
                        };
                        return response.data;
                    }
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async fetchInvoices(workspaceID) {
            try {
                const response = await authenticated.get(`/workspace/${workspaceID}/invoices`)
                if (response?.status === 200) {
                    const index = this.workspaces.findIndex(x => x.id == workspaceID);
                    if (index !== -1) {
                        this.workspaces[index] = {
                            ...this.workspaces[index],
                            invoices: response.data
                        };
                        return response.data;
                    }
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async fetchPaymentMethod(workspaceID, paymentMethodID) {
            try {
                const response = await authenticated.get(`/workspace/${workspaceID}/payment/${paymentMethodID}`)
                if (response?.status === 200) {
                    let workspace = this.workspaces.find((x) => x.id == workspaceID)
                    let foundPaymentMethod = workspace.paymentMethods.find(p => p.id === paymentMethodID)
                    if (foundPaymentMethod) {
                        foundPaymentMethod = response.data
                    } else {
                        workspace.paymentMethods.push(response.data)
                    }
                    return response.data
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async selectPaymentMethod(workspaceID, paymentMethodID) {
            try {
                const response = await authenticated.patch(`/workspace/${workspaceID}/payment/${paymentMethodID}`)
                if (response?.status === 200) {
                    let workspace = this.workspaces.find((x) => x.id == workspaceID)
                    workspace.paymentMethods.forEach(p => p.id === response.data.id ? p.selected = true : p.selected = false)
                    return response.data
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async deletePaymentMethod(workspaceID, paymentMethodID) {
            try {
                const response = await authenticated.delete(`/workspace/${workspaceID}/payment/${paymentMethodID}`)
                if (response?.status === 200) {
                    const workspace = this.workspaces.find(w => w.id === workspaceID);
                    workspace.paymentMethods = response.data
                    return response.data
                }
            }
            catch (error) {
                throw (error)
            }
        },
        setWorkspaces(targetWorkspace) {
            this.currentWorkspace = targetWorkspace
        },
        async fetchActivities(workspaceID) {
            try {
                const response = await authenticated.get(`/workspace/${workspaceID}/activities`)
                if (response?.status === 200) {
                    if (this.currentWorkspace?.id == workspaceID) {
                        this.currentWorkspace['activities'] = response.data
                    }
                    for (let i = 0; i < this.workspaces.length; ++i) {
                        if (this.workspaces[i].id == workspaceID) {
                            this.workspaces[i]['activities'] = response.data
                        }
                    }
                    return response.data
                }
            }
            catch (error) {
                throw (error)
            }
        },
    },
});